<template>
  <main-container>
    <tabbed-display
      :title="$route.name"
      :show-search="true"
      :tab-list="['See', 'Hear', 'Read']"
      :search-criteria="search"
      @update-search="updateSearch"
      @select-tab="selectTab"
    >
      <v-data-iterator :items="sectionContents" :items-per-page="5" :search="search">
        <template #default="{ items }">
          <v-card v-for="item in items" :key="item.id" class="my-2">
            <v-card-text class="text-left">
              <a :href="item.url" target="_blank">{{ item.text }}</a>
              {{ item.note }}
            </v-card-text>
          </v-card>
        </template>
      </v-data-iterator>
    </tabbed-display>
  </main-container>
</template>

<script>
import uniqid from "uniqid";
import MainContainer from "../components/MainContainer.vue";
import TabbedDisplay from "../components/DataDisplays/TabbedDisplay.vue";
export default {
  components: { MainContainer, TabbedDisplay },
  data: () => ({
    section: 0,
    types: [
      ["video", "see"],
      ["audio", "hear"],
      ["text", "read"],
    ],
    search: "",
  }),
  computed: {
    sectionContents() {
      const data = this.$store.state.seeHearRead
        .filter((item) => this.types[this.section].includes(item.type))
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .map((item) => {
          item.id = uniqid();
          return item;
        });

      return data;
    },
  },
  methods: {
    updateSearch(value) {
      this.search = value;
    },
    selectTab(value) {
      this.section = value;
    },
  },
};
</script>
